.orgchart.myChart {
  /* background-color:#141518; */
  background-image: linear-gradient( 90deg, rgb( 30, 32, 36) 0%, rgba(0, 0, 0, 0) 0%), linear-gradient(rgba(33, 90, 136, 0.15) 0%, rgba(0, 0, 0, 0) 0%) !important;
}

.orgchart.myChart>ul>li>ul li::before {
  border-top-color: #9c9c9c;
}

.orgchart.myChart>ul>li>ul li .oc-node::before, .orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: #9c9c9c;
}

.orgchartcontainer {
  background-color: #9c1d1d00;
  border: 2px dashed rgba(93, 68, 68, 0);
  overflow-x: hidden;
}

/* width */

.orgchartcontainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */

.orgchartcontainer::-webkit-scrollbar-track {
  background-color: unset
}

/* Handle */

.orgchartcontainer::-webkit-scrollbar-thumb {
  background-color: unset;
  border-radius: 10px;
}

/* Handle on hover */

.orgchartcontainer::-webkit-scrollbar-thumb:hover {
  background-color: unset
}

.orgchart.myChart .oc-node:hover {
  background-color: unset
}

.orgchart ul li .oc-node.selected {
  background-color: unset
}

.orgchart ul li .oc-node {
  padding: 0px 3px;
}